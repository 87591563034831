import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = ({ data }) => {
  return (
    <Layout>
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>Disclosures and Licenses</h1>
            <h2>Right Start Mortgage Disclosures and License Information</h2>
            <p>
              Right Start Mortgage, Inc. is an Equal Housing Lender. As
              prohibited by federal law, we do not engage in business practices
              that discriminate on the basis of race, color, religion, national
              origin, sex, marital status, age (provided you have the capacity
              to enter into a binding contract), because all or part of your
              income may be derived from any public assistance program, or
              because you have, in good faith, exercised any right under the
              Consumer Credit Protection Act. The federal agency that
              administers our compliance with these federal laws is the Federal
              Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.
            </p>

            <h2>Right Start Mortgage, Inc. State Disclosure Requirements</h2>
            <p>
              Right Start Mortgage, Inc. is a licensed mortgage lender. *The
              following states require disclosure of licensing information.* (If
              your state is not listed, it doesn’t require a specific license
              disclosure):
            </p>

            <ul>
              <li>Alabama Consumer Credit License 23424</li>
<li>Arizona – Mortgage Banker License #BK-0905721</li>
<li>California: Licensed by Dept. of Business Oversight, under the CA Residential Mortgage Lending Act and Finance Lenders Law No 4131234</li>
<li>Colorado – Regulated by the Division of Real Estate</li>
<li>Georgia Mortgage Lender License/Registration, No 35960</li>
<li>Hawaii - Mortgage Servicer License No MS230</li>
<li>Idaho Mortgage Broker/Lender License MBL-2080035960</li>
<li>Kentucky Mortgage Company License MC829663</li>
<li>Louisiana Residential Mortgage Lending License</li>
<li>Mississippi Mortgage Lender License 35960</li>
<li>Nebraska Mortgage Banker License (no number)</li>
<li>Nevada – License # 4256</li>
<li>Ohio Residential Mortgage Lending Act Certificate of Registration RM.805107.000</li>
<li>Oklahoma Mortgage Lender License ML015879</li>
<li>Oregon – Mortgage Lending License No ML-1434</li>
<li>Tennessee Mortgage License 35960</li>
<li>*Texas – Mortgage Lender Registration under the Department of Savings and Mortgage Lending</li>
<li>Washington – Consumer Loan Company License CL-35960</li>

            </ul>

            <p>
              Visit the NMLS access page for more information. <br />{" "}
              <a
                href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/35960"
                target="_blank"
                rel="noopener noreferrer"
              >
                NMLS Access
              </a>
            </p>
            <p>
              In Texas – CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A
              MORTGAGE BANKER OR A LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE
              LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE
              TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 N. LAMAR,
              SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS
              MAY BE OBTAINED FROM THE DEPARTMENT’S WEBSITE AT
              www.sml.texas.gov. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT
              1-877-276-5550. THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE
              PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY
              BORROWERS CAUSED BY ACTS OF LICENSED MORTGAGE BANKER RESIDENTIAL
              MORTGAGE LOAN ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT
              FROM THE RECOVERY FUND MUST BE FILED WITH AND INVESTIGATED BY THE
              DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION
              ABOUT THE RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT’S WEBSITE
              AT www.sml.texas.gov.
            </p>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
